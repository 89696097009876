import { environment } from '../environments/environment';

export const appApiResources = {
  baseUrl: environment.apiHost
};

export const appVariables = {
  locale: 'en-us',
  userLocalStorage: 'user',
  resourceAccessLocalStorage: 'resourceAccessRaw',
  accessTokenServer: 'X-Auth-Token',
  defaultContentTypeHeader: 'application/json',
  defaultContentSecurity: "frame-ancestors 'self';",
  defaultStrictTransportSecurity: 'max-age=31536000 ; includeSubDomains',
  defaultXFrameOptionsHeader: 'DENY',
  unauthorizedPageUrl: '/unauthorized',
  landingPageUrl: '/orderStatus',
  guids: {
    paramGUID: '1ca18e50-dce9-4698-b04d-7d9ecc6b3a30',
    searchGUID: '48803601-8f52-4354-abb5-7698b9ae7af9',
  },
  adminDevGroup: [
    'narayan.chittajallu@ecolab.com',
    'ayesha.bb@ecolab.com',
    'siddharth.dwivedi@ecolab.com',
    'jasreet.dhindsa@ecolab.com',
    'ashutosh.pande@ecolab.com',
    'emily.ollig@ecolab.com',
    'dawn.huestis@ecolab.com',
    'kristin.harder@ecolab.com',
    'joy.li@ecolab.com',
    'saravanakumar.palaniswamy@ecolab.com',
    'ping.wang@ecolab.com',
    'rajeev.roychowdhury@ecolab.com',
    'arvind.dubey@ecolab.com',
    'daniel.stoerzinger@ecolab.com',
    'arjun.boyana@ecolab.com',
    'praveen.rangadhamaiah@ecolab.com',
    'jaspreet.singh@ecolab.com',
    'tapan.bailoor@ecolab.com',
    'crystal.quist@ecolab.com',
    'lauren.kunzer@ecolab.com',
    'sridhar.nivarty@ecolab.com',
    'john.wells@ecolab.com',
    'carles.lopez@ecolab.com',
    'yashwant.jabbu@ecolab.com',
    'sourabh.mishra@ecolab.com',
    'gerard.cuesta@ecolab.com',
    'daron.cox@ecolab.com',
    'ryan.renard1@ecolab.com',
    'murugan.arunachalam@ecolab.com',
    'sri.tayi@ecolab.com',
    'lucreciacassiacorreia.bolognesi@ecolab.com',
    'kaelan.geisser@ecolab.com',
    'ryan.mason3@ecolab.com',
    'dhayes@ecolab.com',
    'linda.johnson@ecolab.com',
    'michelle.stoll@ecolab.com',
    'pnewton@ecolab.com',
    'monique.lewis@ecolab.com',
    'czwhite@ecolab.com',
    'cblignaut@ecolab.com',
    'massimiliano.marta@ecolab.com',
    'gerard.cuesta@ecolab.com',
    'rachel.hart@ecolab.com',
    'kyle.trenberth@ecolab.com',
    'tracy.morrow@ecolab.com',
    'cconger@ecolab.com',
    'kristopher.pasterick@ecolab.com',
    'simone.imbimbo@ecolab.com',
    'alexander.fritz@ecolab.com',
    'christopher.pontius@ecolab.com',
    'montserrat.duran@ecolab.com',
    'azelabidine@ecolab.com',
    'christian.lotz@ecolab.com',
    'katalin.zsiros@ecolab.com',
    'adrienn.sardi@ecolab.com',
    'annika.lyskovmunkehoej@ecolab.com',
    'mari.varis@ecolab.com',
    'dennis.arnold@ecolab.com',
    'rebecca.skelton@ecolab.com',
    'robert.zax@ecolab.com',
    'damian.konieczny@ecolab.com',
    'koen.buntinx@ecolab.com',
    'ruud.bourgonje@ecolab.com',
    'aarti.bandhoesingh@ecolab.com',
    'cedric.seigneuret@ecolab.com',
    'corinne.sylvitus@ecolab.com',
    'khadija.salhi@ecolab.com',
    'mariagrazia.cappelletti@ecolab.com',
    'sabrina.sironi@ecolab.com',
    'jhon.hincapie@ecolab.com',
    'jonathan.frey@ecolab.com',
    'elisabete.rodrigues@ecolab.com',
    'paulo.baptista@ecolab.com',
    'ancilla.lamsma-vandehaak@ecolab.com',
    'cconger@ecolab.com',
    'lcapener@ecolab.com',
    'trent.riter@ecolab.com',
    'fnbna.testam1@nalco.com',
    'fnbna.testam2@nalco.com',
    'fnbna.testcam2@nalco.com',
    'fnbna.testdm1@nalco.com',
    'crmol.test10@nalco.com',
    'justin.baker@ecolab.com',
    'nathan.ziulkowski@ecolab.com',
    'margot.narusiewicz@ecolab.com',
    'kim.johnston@ecolab.com',
    'murad.alyasin@ecolab.com',
    'amit.mistry@ecolab.com',
    'balaji.thiruveedhula@ecolab.com',
    'deepak.raghavan@ecolab.com',
    'edward.felix@ecolab.com',
    'hemanth.bodemu@ecolab.com',
    'smclain@ecolab.com',
    'john.myers@ecolab.com',
    'kurt.watson@ecolab.com',
    'steve.werden@ecolab.com',
    'michael.johannsen@ecolab.com',
    'lori.jarchow@ecolab.com',
    'reshma.gavadi@ecolab.com',
    'essakkiappan.a@ecolab.com',
    'susan.williams2@ecolab.com',
    'tillmann.kleine@ecolab.com',
    'thiago.bittencourtbarcel@ecolab.com',
    'david.taack@ecolab.com',
    'rikin.patel@ecolab.com',
    'benjamin.curless@ecolab.com',
    'precious.callado@ecolab.com',
    'jason.trinidad@ecolab.com',
    'paul.reid@ecolab.com',
    'kayla.givens@ecolab.com',
    'elizabeth.smith1@ecolab.com',
    'john.balquiedra@ecolab.com',
    'herlene.javier@ecolab.com',
    'jobelle.bernil@ecolab.com',
    'richmondadrian.ramos@ecolab.com',
    'leejane.madali@ecolab.com',
    'thomas.lanz@ecolab.com',
    'sarvjeet.lnu@ecolab.com',
    'kirankumar.kadam@ecolab.com',
    'sumanth.venkata@ecolab.com',
    'hiranmoy.sinha@ecolab.com',
    'elango.s@ecolab.com',
    'diane.hrastich@ecolab.com',
    'cristina.charlier@ecolab.com',
    'abhinaba.de@ecolab.com',
    'shrinivas.sabnis@ecolab.com',
    'jeff.kelly@ecolab.com',
    'chris.williams1@ecolab.com',
    'abhinavkumar.singh1@ecolab.com',
    'shamoon.pasha@ecolab.com',
    'spoorti.ambekar@ecolab.com',
    'siddharth.dwivedi@ecolab.com',
    'srinivasan.gopalan@ecolab.com',
    'ayesha.bb@ecolab.com',
    'tejkiran.kushwaha@ecolab.com',
    'ayushi.garg@ecolab.com',
    'ningappa.yellappa@ecolab.com',
    'arunkumar.mathiyalagan@ecolab.com',
    'md.arshad@ecolab.com'
  ],
  orders: {
    title: {
      ntauSearchKeys: ['territory', 'customer'],
      ntauSearchOptions: ['ATHENA_WEB_UI.ORDERS.TITLE.TERRITORY_NO', 'ATHENA_WEB_UI.ORDERS.TITLE.CUSTOMER_SHIP_TO_NO'],
      ntauSearchOptionsPlaceHolders: ['ATHENA_WEB_UI.ORDERS.TITLE.TERRITORY_NO_PLACE_HOLDER', 'ATHENA_WEB_UI.ORDERS.TITLE.CUSTOMER_SHIP_TO_NO_PLACE_HOLDER'],
      salesOrgGUID: '394de137-b7c8-40b2-853c-c9e51fb608ce',
      ntauSalesOrg: [
        {itemKey: { eclEBS: ['AT10', 'AT1G'], nlcSAP: ['4011'] }, itemValue: 'Austria', itemCode: 'AT'},
        {itemKey: { eclEBS: ['CH10', 'CH1G'], nlcSAP: ['4082'] }, itemValue: 'Switzerland', itemCode: 'CH'},
        {itemKey: { eclEBS: ['BE10', 'BE11', 'BE1G'], nlcSAP: ['4020'] }, itemValue: 'Belgium', itemCode: 'BE'},
        {itemKey: { eclEBS: ['CA10'], nlcSAP: ['2001', '2500'] }, itemValue: 'Canada', itemCode: 'CA'},
        {itemKey: { eclEBS: ['DE10', 'DE11', 'DE1G'], nlcSAP: ['4073'] }, itemValue: 'Germany', itemCode: 'DE'},
        {itemKey: { eclEBS: ['DK10', 'DK1G'], nlcSAP: ['4009'] }, itemValue: 'Denmark', itemCode: 'DK'},
        {itemKey: { eclEBS: ['ES10', 'ES1G'], nlcSAP: ['4161'] }, itemValue: 'Spain', itemCode: 'ES'},
        {itemKey: { eclEBS: ['FI10', 'FI1G'], nlcSAP: ['4050'] }, itemValue: 'Finland', itemCode: 'FI'},
        {itemKey: { eclEBS: ['FR10', 'FR1G'], nlcSAP: ['4063'] }, itemValue: 'France', itemCode: 'FR'},
        {itemKey: { eclEBS: ['IT10', 'IT1G'], nlcSAP: ['4112'] }, itemValue: 'Italy', itemCode: 'IT'},
        {itemKey: { eclEBS: ['NL10', 'NL1G'], nlcSAP: ['4126'] }, itemValue: 'Netherlands', itemCode: 'NL'},
        {itemKey: { eclEBS: ['NO10', 'NO1G'], nlcSAP: ['4133'] }, itemValue: 'Norway', itemCode: 'NO'},
        {itemKey: { eclEBS: ['SE10', 'SE1G'], nlcSAP: ['4172'] }, itemValue: 'Sweden', itemCode: 'SE'},
        {itemKey: { eclEBS: ['US10', 'US17'], nlcSAP: ['1001', '1080', '1500'] }, itemValue: 'United States', itemCode: 'US'},
        {itemKey: { eclEBS: [], nlcSAP: ['4011'] }, itemValue: 'Hungary', itemCode: 'HU'},
        {itemKey: { eclEBS: ['PL10', 'PL1G'], nlcSAP: ['4000'] }, itemValue: 'Poland', itemCode: 'PL'},
        {itemKey: { eclEBS: ['PT10', 'PT1G'], nlcSAP: ['4161'] }, itemValue: 'Portugal', itemCode: 'PT'},
        {itemKey: { eclEBS: ['GB10'], nlcSAP: ['4005'] }, itemValue: 'United Kindom', itemCode: 'GB'},
        {itemKey: { eclEBS: ['IE10'], nlcSAP: ['IE10'] }, itemValue: 'Ireland', itemCode: 'IE'},
        {itemKey: { eclEBS: ['MX10','MX11'], nlcSAP: [] }, itemValue: 'Mexico', itemCode: 'MX'}
      ]
    },
    order: {
      listItemColumnNames: [
        'ATHENA_WEB_UI.ORDERS.ORDER.PRODUCT_SKU_NO',
        'ATHENA_WEB_UI.ORDERS.ORDER.PRODUCT_NAME',
        'ATHENA_WEB_UI.ORDERS.ORDER.PRODUCT_QUANTITY',
        'ATHENA_WEB_UI.ORDERS.ORDER.UNIT_OF_MEASURE',
        'ATHENA_WEB_UI.ORDERS.ORDER.INVOICE_AMOUNT',
        'ATHENA_WEB_UI.ORDERS.ORDER.MATERIAL_AVAILABILITY',
      ]
    }
  },
  shared: {
    pagination: {
      displayInfo: 'ATHENA_WEB_UI.SHARED.PAGINATION.INFO'
    },
    lineItem: {
      preparingToShip: 'Preparing To Ship'
    }
  },
};
