import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SearchComponent } from './search/search.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { InteractiveSvgIconComponent } from './interactive-svg-icon/interactive-svg-icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomMaterialModule } from './custom-material.module';
import { TypeAheadComponent } from './type-ahead/type-ahead.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CustomerComponent } from './customer/customer.component';
import { OrderComponent } from './order/order.component';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { LineItemComponent } from './line-item/line-item.component';
import { SidenavService } from './sidenav.service';
import { ApplicationInsightsService } from './application-insights.service';
import { TrackingModalComponent } from './modals/tracking-modal/tracking-modal.component';

@NgModule({
    imports: [CommonModule, TranslateModule, FormsModule, NgbModule, CustomMaterialModule],
    declarations: [SearchComponent, CheckboxComponent, InteractiveSvgIconComponent,
        TypeAheadComponent, SpinnerComponent, CustomerComponent, OrderComponent, DateFormatPipe, LineItemComponent, TrackingModalComponent],
    providers: [SidenavService, ApplicationInsightsService],
    exports: [CommonModule, FormsModule, TranslateModule, NgbModule, SearchComponent, CheckboxComponent,
        TypeAheadComponent, SpinnerComponent, CustomerComponent, InteractiveSvgIconComponent, CustomMaterialModule,
        OrderComponent, DateFormatPipe, LineItemComponent]
})
export class SharedModule { }
