import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { appVariables } from '../app.constants';

@Injectable()
export class StrictTransportSecurityInterceptor implements HttpInterceptor {

  public constructor() {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const StrictTransportSecurity = req.headers.get('Strict-Transport-Security');
    // let clonedRequest: HttpRequest<any>;

    // Clone the request and set the new header.
    if (StrictTransportSecurity) {
      req = req.clone({headers: req.headers.set('Strict-Transport-Security', StrictTransportSecurity)});
      // clonedRequest = req.clone({
      //   setHeaders: {client_id: clientId, client_secret: clientSecret, 'Strict-Transport-Security': StrictTransportSecurity}
      // });
    } else {
      req = req.clone({headers: req.headers.set('Strict-Transport-Security', appVariables.defaultStrictTransportSecurity)});
    }

    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
