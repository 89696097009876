import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { appVariables } from '../app.constants';

@Injectable()
export class XFrameOptionsInterceptor implements HttpInterceptor {

  public constructor() {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const XFrameOptions = req.headers.get('X-Frame-Options');
    // let clonedRequest: HttpRequest<any>;

    // Clone the request and set the new header.
    if (XFrameOptions) {
      req = req.clone({headers: req.headers.set('X-Frame-Options', XFrameOptions)});
      // clonedRequest = req.clone({
      //   setHeaders: {client_id: clientId, client_secret: clientSecret, 'X-Frame-Options': XFrameOptions}
      // });
    } else {
      req = req.clone({headers: req.headers.set('X-Frame-Options', appVariables.defaultXFrameOptionsHeader)});
    }

    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
