<div class="switch-groups">
  <div>
    <form>
      <div class="form-group">
       <label for="switch" style="margin-bottom: 8px;">Need to LogIn with custom email?</label>
        <label class="switch form-control"id="switch">
          <input type="checkbox"  [(ngModel)]="isTM" name="isTMCheckbox">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="form-group" *ngIf="isTM"><br>
         <label for="email" style="margin-bottom: 7px;">Email address:</label>
         <input type="email" class="form-control" [(ngModel)]="email" id="email" name="emailInput">
      </div><br>
       <button type="button" class="btn btn-primary" (click)="onSubmit()" style="margin-top: -5px;">Submit</button>
    </form>
  </div>
</div>
