import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { HttpClientService } from '../http-client.service';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { IUser, IUserAlignmentFromAPI } from './interfaces/app.interface';
import * as _ from 'lodash';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationInsightsService } from '../shared/application-insights.service';
import { appVariables } from '../app.constants';
import { MsalService } from '@azure/msal-angular';
import { AuthError, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';



//const msalInstance = new PublicClientApplication(msalConfig);
@Injectable({ providedIn: 'root' })
export class AuthService {
 
  private readonly restApiURL: string;

  
  

  constructor(private msalService: MsalService, private httpClientService: HttpClientService,
              private userService: UserService, private applicationInsightsService: ApplicationInsightsService) {
    this.restApiURL = environment.apiHost;

   
  }

  authenticateUser(loggedInEmail : string, isImpersonating: boolean):  Observable<any>{
    this.userService.isAuthenticatingUser = true;
    const effectiveEmail = loggedInEmail || 'empty';
    
    return this.httpClientService.get<IUserAlignmentFromAPI>(
      isImpersonating ? `${this.restApiURL}/sales-alignment?imPersonate=${effectiveEmail}` : `${this.restApiURL}/sales-alignment` ).pipe(
      map(
        (response: IUserAlignmentFromAPI) => {
          this.userService.currentUser = this.covertUserAlignmentFromAPI(effectiveEmail, response);
          this.userService.isAuthenticatingUser = false;
        }), 
        catchError((error: HttpErrorResponse) => {
          console.log("authenticateUser",error);
        this.applicationInsightsService.trackExceptions(error);
        return throwError(error);
      }));
    }

  
  
  


  public checkScheduledMaintainance(): Observable<any> {
    return this.httpClientService.post<any>(`${this.restApiURL}/sales-alignment`).pipe(
      map(data => {
        return data;
      },
      catchError((err: any) => throwError(err))
    ));
  }
  
  private covertUserAlignmentFromAPI(loggedInEmail: string, userAlignmentRes: IUserAlignmentFromAPI): IUser {
    const userDetails = {} as IUser;
    userDetails.email = loggedInEmail;

    if (sessionStorage.getItem(appVariables.guids.paramGUID) && sessionStorage.getItem(appVariables.guids.searchGUID)) {
      userDetails.isTerritoryManager = false;
    } else if (userAlignmentRes.territories && userAlignmentRes.territories.length > 0) {
      userDetails.isTerritoryManager = true;
      userDetails.territories = _.cloneDeep(userAlignmentRes.territories);
    } else {
      userDetails.isTerritoryManager = false;
    }

    return userDetails;
  }



getMsalInstance(): IPublicClientApplication {
  return this.msalService.instance;
}



}

