import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, filter, mergeMap, retry, switchMap, take, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, PopupRequest, AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication, AzureCloudInstance } from "@azure/msal-browser";
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  public constructor(private msalService:MsalService) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    var request ={
      scopes:[environment.authScope],
      account: this.msalService.instance.getAllAccounts()[0]
    }

    return new Observable(observer =>
       {
       this.msalService.instance.acquireTokenSilent(request).then((tokenResponse:AuthenticationResult) => {
          const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${tokenResponse.accessToken}`),
          });
         return next.handle(authReq).subscribe(
            event => observer.next(event),
           err => observer.error(err),
           () => observer.complete()
         );
       }).catch(error =>
       {
        
        if (error instanceof InteractionRequiredAuthError) {
            console.log("AuthorizationInterceptor error",error);
  
        }
      })
      
    });
   
  }

}