/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentTypeInterceptor } from './content-type-interceptor';
import { AuthorizationInterceptor } from './authorization-interceptor';
import { ContentSecurityInterceptor } from './Content-Security-Policy-interceptor';
import { StrictTransportSecurityInterceptor } from './Strict-Transport-Security-interceptor';
import { XFrameOptionsInterceptor } from './X-Frame-Options-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentSecurityInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: StrictTransportSecurityInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: XFrameOptionsInterceptor, multi: true },
];
