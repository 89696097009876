import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './components/app.component';
import { AuthenticationGuard } from 'microsoft-adal-angular6';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', component: AppComponent, pathMatch: 'full', canActivate: [MsalGuard] },
  { path: 'shipto/:shipto', component: AppComponent },
  { path: 'orderStatus', loadChildren: () => import('./feature-modules/orders/orders.module').then(m => m.OrdersModule), canActivate: [MsalGuard] },
  { path: '**', component: AppComponent, canActivate: [MsalGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
