export const environment = {
  production: false,
  env: 'qa',
  applicationInsightsKey: '99db99e9-3be5-4d44-b8da-0c9106492ede',
  authScope:"961ac727-a5e2-400a-ae93-0ae93ce97520/.default",
  apiHost: 'https://gsc-orderstatus-appservice-001-q.azurewebsites.net', // TODO: This needs to be changed when mulesoft has API's ready
  adalConfig: {
    tenant: 'c1eb5112-7946-4c9d-bc57-40040cfe3a91',
    clientId: '27bbfa50-6d80-4d71-9d6b-b038726371cb',
    endpoints: {
      'https://gsc-orderstatus-appservice-001-q.azurewebsites.net': '961ac727-a5e2-400a-ae93-0ae93ce97520'
    }
  }
};
