import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { appVariables } from '../app.constants';

@Injectable()
export class ContentSecurityInterceptor implements HttpInterceptor {

  public constructor() {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const ContentSecurity = req.headers.get('Content-Security-Policy');
    // let clonedRequest: HttpRequest<any>;

    // Clone the request and set the new header.
    if (ContentSecurity) {
      req = req.clone({headers: req.headers.set('Content-Security-Policy', ContentSecurity)});
      // clonedRequest = req.clone({
      //   setHeaders: {client_id: clientId, client_secret: clientSecret, 'Content-Security-Policy': ContentSecurity}
      // });
    } else {
      req = req.clone({headers: req.headers.set('Content-Security-Policy', appVariables.defaultContentSecurity)});
    }

    // send cloned request with header to the next handler.
    return next.handle(req);
  }
}
